import React, { useState } from "react"
import {
  Container,
  Box,
  Typography,
  Hidden,
  Button,
  IconButton,
} from "@material-ui/core"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { makeStyles } from "@material-ui/core/styles"
import LocalizedLink from "./LocalizedLink"
import Swipe from "react-easy-swipe"

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(15),
    },
  },
  carousel: {
    marginTop: theme.spacing(3.5),
    transition: "transform 0.2s ease 0s",

    // Add margins to carousel so overflow card edges are visible
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),

    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
    },
  },
  viewAll: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
}))

const ArrowButton = ({ back, disabled, onClick, classes }) => (
  <IconButton
    edge={back ? "start" : "end"}
    onClick={onClick}
    style={{
      backgroundColor: "#FFF",
      boxShadow: "0 4px 40px 0 rgba(120,122,128,0.06)",
      marginRight: back ? "8px" : 0,
      padding: "10px",
    }}
    disabled={disabled}
  >
    {back ? (
      <div className={classes.arrow}>
        <ArrowBackIcon fontSize="small" />
      </div>
    ) : (
      <div className={classes.arrow}>
        <ArrowForwardIcon fontSize="small" />
      </div>
    )}
  </IconButton>
)

const CarouselSection = ({
  children,
  cardsData,
  title,
  description,
  indexBtn,
  rackSize,
}) => {
  const classes = useStyles()

  const [carouselIndex, setCarousel] = useState(0)

  const onClickLeft = () => {
    const increment = carouselIndex <= 0 ? 0 : -1
    setCarousel(carouselIndex + increment)
  }

  const onClickRight = () => {
    const increment = carouselIndex >= cardsData.length - rackSize ? 0 : 1
    setCarousel(carouselIndex + increment)
  }

  return (
    <div className={classes.root}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box width={{ sm: 1, md: 0.35 }}>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="subtitle1">{description}</Typography>
          </Box>
          {indexBtn ? (
            <Box>
              <Button
                component={LocalizedLink}
                to={indexBtn.path}
                endIcon={<ArrowForwardIcon />}
              >
                {indexBtn.label}
              </Button>
            </Box>
          ) : (
            <Box>
              <ArrowButton
                back
                disabled={carouselIndex <= 0}
                onClick={onClickLeft}
                classes={classes}
              />
              <ArrowButton
                disabled={carouselIndex >= cardsData.length - rackSize}
                onClick={onClickRight}
                classes={classes}
              />
            </Box>
          )}
        </Box>
      </Container>

      <Swipe
        onSwipeLeft={() => onClickRight()}
        onSwipeRight={() => onClickLeft()}
      >
        <Box
          display="flex"
          className={classes.carousel}
          style={{
            transform: `translateX(${(carouselIndex * -100) / rackSize}%)`,
          }}
        >
          {children}
        </Box>
      </Swipe>
      <Hidden mdUp>
        <Container>
          {/* {indexBtn ? (
            <Button
              component={LocalizedLink}
              className={classes.viewAll}
              to={indexBtn.path}
              endIcon={<ArrowForwardIcon />}
            >
              {indexBtn.label}
            </Button>
          ) : null} */}
        </Container>
      </Hidden>
    </div>
  )
}

export default CarouselSection
