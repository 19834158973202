import { useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"

const useRackSize = () => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))
  const isSm = useMediaQuery(theme.breakpoints.only("sm"))
  const isMd = useMediaQuery(theme.breakpoints.only("md"))
  const isLg = useMediaQuery(theme.breakpoints.only("lg"))
  const isXl = useMediaQuery(theme.breakpoints.only("xl"))

  const rackSizes = [
    { breakpoint: isXs, size: 1 },
    { breakpoint: isSm, size: 2 },
    { breakpoint: isMd, size: 3 },
    { breakpoint: isLg, size: 4 },
    { breakpoint: isXl, size: 4 },
  ]

  let rackSize = 1
  rackSizes.forEach(bp => {
    if (bp.breakpoint) {
      rackSize = bp.size
    }
  })

  return rackSize
}

export default useRackSize
